import { AnalyticsManager } from './analytics/AnalyticsManager';
import { ConvivaAnalyticsTracker } from './analytics/ConvivaAnalyticsTracker';
import { KeyMomentsConvivaAnalyticsTracker } from './analytics/KeyMomentsConvivaAnalyticsTracker';
import {
  seekReceiverWithSeekedSender,
  setReceiverVolume,
  textTracksChange
} from './cast/CastHelper';
import { adCuePoints } from './components/AdCuePoints';
import { showContentDescriptor } from './components/ContentDescriptor';
import { addGoLiveButton } from './components/GoLiveButton';
import { addWaterMark, fetchConcurrentUsers } from './components/WaterMark';
import { AdClient, AdConfig } from './configs/AdConfig';
import { DRMConfig } from './configs/DRMConfig';
import { Media, Medium } from './configs/Media';
import { MimeType } from './configs/MimeTypes';
import { PlayerConfig } from './configs/PlayerConfig';
import {
  SpApiResponse,
  AssetDetails,
  SubtitleURL
} from './configs/SpApiResponse';
import { TextTrack, TextTrackKind } from './configs/TextTrack';
import { ZeePlayerConfig } from './configs/ZeePlayerConfig';
import { constants } from './constants/playerConstants';
import { playerEventsConstants } from './constants/playerConstants';
import { KeySystem } from './drm/KeySystem';
import { AdEvents } from './events/AdEvents';
import { Events } from './events/Events';
import { MediaPlayer } from './MediaPlayer';
import {
  checkAdsAvailability,
  checkIfAdsUrlPresent,
  getDAICustomParameters
} from './utils/AdHelper';
import {
  onPlayEventHelper,
  onPlayEndedEventHelper,
  fullScreenEvent,
  registerEvents,
  playerProgress,
  triggerWatchHistory,
  onErrorHelper,
  subtitleChange,
  triggerRecoSkipButton,
  onTimeUpdateEventHelper,
  getActiveSubtitles,
  triggerMandatoryPopup,
  onPauseEventHelper,
  onSeekSrubEventHelper,
  audioChange,
  videoQualityChange,
  showIsTrailer,
  convivaSessionOnReady,
  convivaSessionKeyMomentsOnReady,
  reportVideoViewEvent,
  ifAssetIdChanges,
  onVolumeChange,
  onmuteChange,
  handleStaleApiCall,
  toggleSubscribeTeaserBanner,
  subscriptionNudgeOnPlayerPopup,
  removeSubscriptionNudgeOnPlayer,
  updateUpnextState,
  createLanguageSelectionUnit,
  showDownloadAppMessage,
  updateFreeMinutes,
  getFreeMinutes,
  setFreeMinutes,
  SOShandler,
  showErrorMessage,
  getFormattedErrorCode,
  showErrorMessageOnLicenseFails,
  subtitleBtnEvent,
  updateVideoResolutionTag,
  showTobaccoAdvisoryPreRollVideo,
  checkIfTobaccoAdvisory,
  intervalTimerRefForPinScoreCard,
  displayScoreCard,
  detectChromeCast,
  displayWatchAndWin,
  checkAdsInterruptionThreshold
} from './utils/playerEventsHelper';
import {
  getCookieData,
  getLocalStorage,
  setLocalStorage,
  getBrowserType,
  getLanguageName,
  getLocalStorageInteger,
  isIos,
  toggleKeyMoments,
  getSessionStorage,
  setSessionStorage,
  fetchSelfErrors,
  getSelectedQualityValue,
  deleteLocalStorage,
  removeContinueWatchAfterPlaybackEnd,
  mergeObjects,
  isMobileDevice,
  isIpadChrome
} from './utils/Utils';
import { ErrorCode } from '../src/error/Error';
import { FeatureFlagConstant } from './utils/FeatureFlagConstant';
let convivaAnalyticsTracker: any;
declare global {
  interface Window {
    avodNudgeHandler: any;
    translationsAndConfigs: any;
    parentalPinPopUpEnabled: boolean;
    chromePopupDisplayed: any;
    drmSessionError: boolean;
    convivaSDK: any;
    videoPlayer: any;
    parentalPinIncorrect: boolean;
    Conviva: any;
    convivaAnalytic: any;
    analyticsManager: any;
    is_dai: boolean;
    isLive: boolean;
    isConvivaSessionRequired: boolean;
    appVersion: string;
    autoSeek: boolean;
    contentExit: any;
    adsLoader: any;
    playerAnalyticsObject: any;
    convivaAdAnalytics: any;
    isConvivaAdSessionRequired: boolean;
    mixpanel: any;
    VmaxVideoJSPlugin: any;
    VMAXSDK: any;
    isSportsLive: boolean;
    keyMomentsconvivaAnalytic: any;
    isCastConnected: boolean;
    isCastAvailable: boolean;
    isPlayingEventSent: any;
    firstRequestTriggered: boolean;
    userInteracted: boolean;
    storeMixPanelMetricsInSS: any;
    playerSeconds: number;
    playerReloadTimer: any;
    clearPlayerReloadTimer: any;
    enableReloadPlayerOnTimeout: any;
    showErrorMessageOnLicenseFails: any;
    isClearMedia: any;
    triggerFallbackPlayback: any;
    videojs: any;
  }
}

export default class ZeeVideoJSPlayer {
  keySystem = new KeySystem();
  playerConfig: ZeePlayerConfig;
  videoPlayer: any;
  playableData: AssetDetails = null;
  isPlayerEventRegistered: boolean;
  singlePlayDetails: SpApiResponse;
  similarFromReco: any;
  translationsAndConfigs: any;
  isDai: boolean;
  preRollForDaiConfig: boolean;
  licenseCertHeaders: Map<string, string>;
  contentWithNoTrailer: boolean;
  isRecoPending: boolean;
  playerDisposedFlag = true;
  contentDescriptorHidden: boolean;
  isNewsCatchup: boolean;
  isAdPlaying = false;
  mixpanelData: any;
  isPlayingClearContent = false;
  metaDataLoaded = false;
  displayLanguage: string;
  analyticsManager: AnalyticsManager;
  mandatoryVideoCountUpdated: boolean;
  preRollDetectedorFailed: boolean;
  isVisible = true;
  mediaObject: Media;
  setPlayerHaltedFlag: boolean;
  defaultPlayerConfig: any;
  isStaleApisCalled: boolean;
  validContentTypesForStaleApi: string[];
  firstPlay = false;
  prevCapping = null;
  concurrentUsersTimer: any;
  isPopUpVideo = false;
  isSportsLive = false;
  sosAssetType: any;
  sosAssetSubtype: any;
  sosCapping: any;
  freeMinutesTimer: any;
  showFreeMinutesTimer: boolean;
  freeAvailableSeconds = 900;
  isTrailer = false;
  hasTobaccoAdvisoryVideo = false;
  preRollTobaccoAdvisoryVideoShown = false;
  showTobaccoAdvisoryWithoutPreRoll = false;
  // retry logic for player timout
  playerReloadTimer: any;
  incrementPlayerCounter: any;
  videoPlayed = false;
  reloadCount = 0;
  // retry logic
  isSOSEnabled = false;
  sportsLiveRetryCount = 0;
  dai_sports_error = false;

  constructor(config: ZeePlayerConfig) {
    this.playerConfig = config;
    this.videoPlayer;
    this.isPlayerEventRegistered = false;
    this.setDataFromPlayerConfig();
    this.contentWithNoTrailer = false;
    this.isRecoPending = true;
    this.similarFromReco = [];
    this.contentDescriptorHidden = true;
    this.isNewsCatchup = false;
    this.preRollForDaiConfig = false;
    this.analyticsManager = AnalyticsManager.getInstance();
    this.mandatoryVideoCountUpdated = false;
    this.preRollDetectedorFailed = false;
    this.isStaleApisCalled = false;
    this.validContentTypesForStaleApi = ['video', 'episode'];
    this.sosAssetType = null;
    this.sosAssetSubtype = null;
    this.sosCapping = null;
    this.showFreeMinutesTimer = false;

    if (this.playerConfig.videoTagId === 'video-tag-popup') {
      this.isPopUpVideo = true;
      convivaAnalyticsTracker = new KeyMomentsConvivaAnalyticsTracker();
      window.convivaAnalytic.reportEvent(
        window.Conviva.Constants.Events.USER_WAIT_STARTED
      );
    } else {
      convivaAnalyticsTracker = new ConvivaAnalyticsTracker();
    }
    document.addEventListener(
      'visibilitychange',
      this.handleVisibilityChange.bind(this),
      false
    );
    screen.orientation.addEventListener(
      'change',
      this.handleOrientationChange.bind(this),
      false
    );
    this.freeMinutesTimer = null;
  }

  // Same as load media token function
  setDataFromPlayerConfig(): void {
    this.playerConfig = { ...this.playerConfig };
    this.singlePlayDetails = this.playerConfig.singlePlayDetails;
    this.mixpanelData = this.playerConfig.mixpanelData || {};
    this.displayLanguage = this.playerConfig.displayLanguage;
  }

  getPlayableData(): any {
    const {
      entitlement,
      keyOsDetails,
      assetDetails,
      trailerDetails,
      error_code,
      error_msg = ''
    } = this.singlePlayDetails || {};
    const content_partner_details =
      this.singlePlayDetails?.assetDetails?.content_partner_details;
    const {
      isIncognitoOn: isIncognitoMode = false,
      toggleWatchTrailerButton = null
    } = this.playerConfig || {};
    const { isPremiumPlayback: isPremiumContent = false } = entitlement || {};
    const { is_drm: isDRMPlayback } = assetDetails || {};
    const isTelcoUser = getLocalStorage('isTelcoUser');
    const partnerName =
      getCookieData('partner') || getLocalStorage('partnerName');
    // telco
    if (content_partner_details?.content_partner_id) {
      this.playerConfig.triggerTeaserBanner({
        playerRef: this
      });
    }
    if (
      isIncognitoMode &&
      isPremiumContent &&
      isDRMPlayback &&
      !isTelcoUser &&
      !partnerName
    ) {
      // should create and handle the below function for telco partner - new to be implemneted some error msg
      // ztvlib.eventmanager.incognitoPremiumPlaybackMessage();
      return null;
    }
    // normal content
    else if (entitlement && keyOsDetails) {
      const {
        isAVOD,
        isPremiumPlayback,
        isTrailer,
        isLive,
        is_dai = 0
      } = entitlement;
      this.isDai = is_dai ? true : false;
      if (
        ((isAVOD && !isTrailer) || isPremiumPlayback) &&
        ((assetDetails.is_drm && keyOsDetails.sdrm) ||
          (!assetDetails.is_drm && keyOsDetails.hls_token))
      ) {
        // currently isTrailer is 0 for trailer contents so handled with asset_subtype.
        if (
          assetDetails.asset_subtype === 'trailer' &&
          toggleWatchTrailerButton
        ) {
          toggleWatchTrailerButton({ disable: true });
          this.isTrailer = true;
        } else if (toggleWatchTrailerButton) {
          toggleWatchTrailerButton({ disable: false });
        }
        return assetDetails;
      } else if (isTrailer && keyOsDetails.sdrm) {
        toggleWatchTrailerButton({ disable: true });
        this.isTrailer = true;
        return trailerDetails;
      } else if (isLive && keyOsDetails.video_token) {
        return assetDetails;
      }
    }
    // spapi error handling before playback
    else if (
      error_code === '3608' ||
      error_code === '606' ||
      error_code === '610' ||
      error_code === '607' ||
      error_code === '3804' ||
      error_code === '601' ||
      error_code === '3609' ||
      error_code === '3610' ||
      error_code === '3814' ||
      error_code === '3815' ||
      error_code === '3614' ||
      (error_code && error_code.toString()) == '3603'
    ) {
      if (error_code === '3614') {
        this.playerConfig.compatibilityMessage({ error_code, error_msg });
        return null;
      }
      if (
        this.playerConfig.callStaledApis &&
        this.validContentTypesForStaleApi.includes(
          this.playerConfig.contentType
        )
      ) {
        this.playerConfig.callStaledApis();
      }
      if (error_code === '3814') {
        this.playerConfig.svodOrTvodPopupComponent({
          playerRef: this,
          videoPlayer: null,
          isFullScreenFlag: false
        });
        return assetDetails;
      }
      return assetDetails;
    }
    // if user is not registered & there is no trailer, we have nothing to play
    else if (
      !this.contentWithNoTrailer &&
      error_code === '604' &&
      this.playerConfig.tvodObj &&
      window &&
      window.translationsAndConfigs
    ) {
      this.contentWithNoTrailer = true;
      this.playerConfig.svodOrTvodPopupComponent({
        playerRef: this,
        videoPlayer: null,
        isFullScreenFlag: false
      });
    }
    return null;
  }

  // playback flow function start here ******
  initPlaybackFlow() {
    const {
      error_code = '',
      error_msg = '',
      entitlement,
      parentalControlPin = ''
    }: any = this.singlePlayDetails || {};
    const { isTrailer, isAdAuthenticated } = entitlement || {};
    const {
      tvodObj = null,
      svodOrTvodPopupComponent,
      adultPopupComponent,
      userType = 'guest'
    } = this.playerConfig || {};

    const { enabled, payload } =
      this.playerConfig.getFeatureFlagConfig('config_tobacco');

    const tobaccoAdvisoryConfig = enabled ? payload : {};

    let containsHealthspot = false;
    if (
      this.singlePlayDetails?.tobaccoAdvisoryDetails?.video_segments &&
      tobaccoAdvisoryConfig?.video_segment_config?.enable
    ) {
      this.hasTobaccoAdvisoryVideo = true;
    }
    if (checkIfTobaccoAdvisory(this.singlePlayDetails, tobaccoAdvisoryConfig)) {
      containsHealthspot = true;
    }
    convivaAnalyticsTracker.updateConvivaInfo({
      containsHealthspot: containsHealthspot
    });
    if (
      error_code &&
      (error_code === '3804' || // subscription error
        error_code === '604' || // tvod scubscription error with no trailer
        error_code === '603') // before tv which doesnt have trailer subscription error
    ) {
      if (!this.isPlayerEventRegistered) {
        // passing reference of player object
        registerEvents(this, this.isPopUpVideo);
      }
      if (error_code === '3804') {
        // getting error code 3804 for content with no trailer
        // window.ztvlib.makeShdCall = (a, b) => makeShdCallPlayer(a, b); // handling this case for shd call PWA2-12072
        this.playerConfig.svodOrTvodPopupComponent({
          playerRef: this,
          videoPlayer: null,
          isFullScreenFlag: false
        });
      }
      if (error_code === '604' && tvodObj) {
        this.similarFromReco = [];
        svodOrTvodPopupComponent({
          playerRef: this,
          videoPlayer: this.videoPlayer,
          isFullScreenFlag: false
        });
      }
      // else if (error_code !== '604') {
      //   svodOrTvodPopupComponent({ playerRef: this, videoPlayer: this.videoPlayer, isFullScreenFlag: false });
      // }
    } else if (
      (isAdAuthenticated && !isTrailer) ||
      (error_code && error_code === '601')
    ) {
      adultPopupComponent({
        isAdvertisementAuthenticated: false,
        isFullScreenFlag: false
      });
    } else if (
      (error_code && error_code.toString()) == '3603' ||
      error_code === '610' ||
      error_code === '607'
    ) {
      if (this.playerConfig && this.playerConfig.compatibilityMessage) {
        this.playerConfig.compatibilityMessage({ error_code, error_msg });
      }
    } else if (error_code === '3609') {
      if (this.playerConfig && this.playerConfig.compatibilityMessage) {
        this.playerConfig.compatibilityMessage({ error_code, error_msg });
      }
    } else if (error_code === '3610') {
      if (this.playerConfig && this.playerConfig.compatibilityMessage) {
        this.playerConfig.compatibilityMessage({ error_code, error_msg });
      }
    } else if (error_code === '606') {
      window.parentalPinPopUpEnabled = true;
      convivaAnalyticsTracker.createConvivaSessionParental(this);
      if (userType === 'guest') {
        adultPopupComponent({
          isAdvertisementAuthenticated: false,
          isFullScreenFlag: false
        });
      } else if (parentalControlPin || parentalControlPin === 0) {
        this.playerConfig.parentalPinPopupComponent({ playerRef: this });
      } else {
        this.playerConfig.handleParentalPin();
      }
      registerEvents(this, this.isPopUpVideo);
    } else {
      this.initializePlaybackType();
    }
  }

  // differentiates the playback types and handles the plyback flow
  initializePlaybackType() {
    window.showErrorMessageOnLicenseFails = showErrorMessageOnLicenseFails;
    window.isClearMedia = this.getClearMedia();
    window.triggerFallbackPlayback = this.initializeFallbackPlayback.bind(this);
    this.setLicenseCertHeaders();
    const protectedMedia = this.getProtectedMedia();
    if (
      this.isTrailer &&
      this.playableData &&
      this.playableData.hls_url &&
      Array.isArray(this.playableData.audio_languages) &&
      this.playableData.audio_languages.length === 1
    ) {
      this.initializeFallbackPlayback();
    } else if (protectedMedia) {
      this.isPlayingClearContent = false;
      this.initMediaPlayer(protectedMedia);
    } else {
      this.initializeFallbackPlayback();
    }
  }

  // Get the non protected media configurations
  getClearMedia(): Media {
    const media: Media = {
      title: '',
      type: MimeType.APPLICATION_M3U8,
      medium: Medium.VIDEO,
      poster: this.playerConfig.posterImage
    };
    if (this.isTrailer && this.playableData && this.playableData.hls_url) {
      media.src = this.playableData.hls_url;
    } else if (
      this.singlePlayDetails.keyOsDetails &&
      this.singlePlayDetails.keyOsDetails.hls_token
    ) {
      media.src = this.singlePlayDetails.keyOsDetails.hls_token;
    } else if (
      this.singlePlayDetails.keyOsDetails &&
      this.singlePlayDetails.keyOsDetails.video_token
    ) {
      if (
        this.playerConfig.startTime &&
        this.playerConfig.endTime &&
        window.location.href.includes('start') &&
        window.location.href.includes('end')
      ) {
        this.isNewsCatchup = true;
        media.src = `${this.singlePlayDetails.keyOsDetails.video_token}&start=${this.playerConfig.startTime}&end=${this.playerConfig.endTime}`;
      } else {
        this.isNewsCatchup = false;
        media.src = this.singlePlayDetails.keyOsDetails.video_token;
      }
    }
    return media.src ? media : null;
  }

  // Get the protected media configurations
  getProtectedMedia(): Media | null {
    //TODO: REMOVE LATER
    if (this.singlePlayDetails && this.singlePlayDetails.timeStamp) {
      const timeStamp = this.singlePlayDetails.timeStamp;
      const timeDiff = Math.floor((new Date() - timeStamp) / 1000) / 60;
      if (timeDiff >= 2) {
        const eventPayload = {
          type: 'SINGLE_PLAY_TIME_STAMP_EXPIRED'
        };
        this.sendConvivaReportEvent(eventPayload);
      }
    }
    const { entitlement, keyOsDetails } = this.singlePlayDetails || {};
    let dashStreamURL = '';
    let hlsStreamURL = '';

    if (keyOsDetails && keyOsDetails.nl && keyOsDetails.sdrm) {
      const browserType = getBrowserType();
      if (
        this.playableData.video_url &&
        (this.playableData.video_url['mpd'] ||
          this.playableData.video_url['m3u8'])
      ) {
        dashStreamURL = this.playableData.video_url['mpd'];
        hlsStreamURL = this.playableData.video_url['m3u8'];
        return browserType === 'Safari'
          ? this.getFairplayConfig(hlsStreamURL)
          : this.getWidevineConfig(dashStreamURL);
      } else {
        // handle when video_url is not present
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    }

    return null;
  }

  // code will return widevine DRM configurations
  getWidevineConfig(streamURL: string): Media {
    const widevineConfig: DRMConfig = {
      drm: KeySystem.WIDEVINE,
      licenseUri: this.playerConfig.licenseUrl + constants.widevineLicenseUri,
      certificateUri:
        this.playerConfig.licenseUrl + constants.widevineLicenseUri,
      licenseHeaders: this.licenseCertHeaders,
      certificateHeaders: this.licenseCertHeaders
    };
    return {
      title: '',
      src: streamURL,
      type: MimeType.APPLICATION_MPD,
      medium: Medium.VIDEO,
      poster: this.playerConfig.posterImage,
      keySystems: this.keySystem.getConfigs(widevineConfig)
    };
  }

  getMimeType() {
    const mimeTypeCheckEnabledFromConfig = this.playerConfig.isFeatureEnabled(
      FeatureFlagConstant.FEATURE_MIMETYPE_CHECK_ENABLED
    );

    return isIpadChrome() && mimeTypeCheckEnabledFromConfig
      ? MimeType.APPLICATION_MP4
      : MimeType.APPLICATION_M3U8;
  }

  // code will return FairPlay DRM configurations
  getFairplayConfig(streamURL: string): Media {
    const fairPlayConfig: DRMConfig = {
      drm: KeySystem.FAIRPLAY,
      certificateUri: constants.fairPlayCertificateUri,
      licenseUri: this.playerConfig.licenseUrl + constants.fairPlayLicenseUri,
      licenseHeaders: this.licenseCertHeaders
    };
    return {
      title: '',
      src: streamURL,
      type: this.getMimeType(),
      medium: Medium.VIDEO,
      poster: this.playerConfig.posterImage,
      keySystems: this.keySystem.getConfigs(fairPlayConfig)
    };
  }

  setLicenseCertHeaders(): void {
    this.licenseCertHeaders = new Map();
    this.licenseCertHeaders.set('nl', this.playerConfig.nl);
    this.licenseCertHeaders.set('customdata', this.playerConfig.customdata);
  }

  initMediaPlayer(media: Media) {
    if (
      media &&
      !this.isNewsCatchup &&
      this.singlePlayDetails &&
      (this.isDai || this.singlePlayDetails.adDetails)
    ) {
      media.advertising = this.getAdconfig();
    } else if (this.hasTobaccoAdvisoryVideo) {
      this.showTobaccoAdvisoryWithoutPreRoll = true;
    }
    const eventPayload = {
      type: 'MEDIA_SOURCE_LATEST',
      payload: JSON.stringify(media)
    };

    this.sendConvivaReportEvent(eventPayload);

    const defaultConfig: PlayerConfig = {
      media: media,
      autoplay: true, // 'any' retry itself to auto play
      muted:
        (this.videoPlayer &&
          this.videoPlayer.player &&
          this.videoPlayer.player() &&
          this.videoPlayer.getMutedState()) ||
        false,
      loop: false,
      controls: true,
      errorDisplay: false,
      logo: '',
      sportsLive: this.isSportsLive,
      liveui: this.isSportsLive ? true : false
    };

    let mergedPlayerConfig = { ...defaultConfig };

    let playerConfiguarionFromOffers = {};
    if (isMobileDevice()) {
      const { enabled, payload } = this.playerConfig.getFeatureFlagConfig(
        FeatureFlagConstant.CONFIG_PLAYER_OPTIONS_MOBILE
      );
      playerConfiguarionFromOffers = enabled ? payload : {};
    }
    const { enabled, payload } = this.playerConfig.getFeatureFlagConfig(
      FeatureFlagConstant.CONFIG_PLAYER_OPTIONS_DESKTOP
    );
    playerConfiguarionFromOffers = enabled ? payload : {};

    mergedPlayerConfig = mergeObjects(
      defaultConfig,
      playerConfiguarionFromOffers
    );

    if (this.playerDisposedFlag) {
      this.playerDisposedFlag = false;
      const { enabled, payload } = this.playerConfig.getFeatureFlagConfig(
        FeatureFlagConstant.CONFIG_ADS
      );
      const adsUtilsData = enabled ? payload : {};
      this.videoPlayer = new MediaPlayer(
        this.playerConfig.videoTagId,
        adsUtilsData
      );
    }
    this.videoPlayer.destroy();

    if (this.isDai) {
      media = {
        ...media,
        cust_params: getDAICustomParameters(
          this.mixpanelData,
          this.playerConfig,
          this.singlePlayDetails
        )
      };
      const { enabled, payload } = this.playerConfig.getFeatureFlagConfig(
        FeatureFlagConstant.CONFIG_SPORTS
      );
      const offersDataForSports = enabled ? payload : {};
      let forced_dai_fallback_to_clear_content = false;
      if (offersDataForSports?.forced_dai_fallback_to_clear_content) {
        forced_dai_fallback_to_clear_content =
          offersDataForSports.forced_dai_fallback_to_clear_content;
      }

      // fallback to clear hls when dai fails
      if (
        this.isSportsLive &&
        this.dai_sports_error &&
        forced_dai_fallback_to_clear_content
      ) {
        delete media.cust_params;
        delete media.advertising;
      }
    }

    if (this.isVisible) {
      this.videoPlayer.setup(mergedPlayerConfig, media);
      if (isIos()) {
        setTimeout(() => {
          if (!this.firstPlay && !window.isLive) {
            showDownloadAppMessage();
          }
        }, 25000);
      }
      // this.videoPlayer.setSource(media);
    } else {
      this.mediaObject = media;
      this.defaultPlayerConfig = mergedPlayerConfig;
      this.setPlayerHaltedFlag = true;
    }
    this.eventHandler(this.videoPlayer);
  }

  initializeFallbackPlayback() {
    const clearMedia = this.getClearMedia();
    this.isPlayingClearContent = true;
    if (clearMedia) {
      this.initMediaPlayer(clearMedia);
    }
  }

  // playback flow function end here *******

  // code will generate the AD configurations based on condition
  getAdconfig(): Adconfig {
    const { entitlement } = this.singlePlayDetails || {};
    const { isLive } = entitlement;
    const vmaxAds = this.getVmaxAdConfigurations();
    if (vmaxAds) {
      return vmaxAds;
    } else {
      if (isLive) {
        const isPreRollAvailable = checkAdsAvailability(this.singlePlayDetails);
        if (isPreRollAvailable && !this.preRollForDaiConfig) {
          this.preRollForDaiConfig = true;
          return this.getGoogleIMAConfigurations();
        } else {
          if (this.isDai && this.singlePlayDetails.keyOsDetails) {
            return this.getGoogleDaiConfigurations();
          }
        }
      } else {
        return this.getGoogleIMAConfigurations();
      }
    }
  }

  // Code will generate Vmax configurations
  getVmaxAdConfigurations(): Adconfig {
    const {
      adDetails: {
        adPriority: { VMAX = 0, DFP = 0 } = {},
        VMAX: adDetailsVmax = {}
      } = {}
    } = this.singlePlayDetails || {};
    if (VMAX === 1 && DFP === 2 && adDetailsVmax) {
      return {
        client: AdClient.VMAX,
        vmaxSettings: { ads: adDetailsVmax }
      };
    }
    return false;
  }

  // Code will generate Google IMA configurations
  getGoogleIMAConfigurations(): Adconfig {
    const adResponse = checkAdsAvailability(this.singlePlayDetails);
    const isAdsUrlPresent = checkIfAdsUrlPresent(this.singlePlayDetails);
    const { enabled, payload } = this.playerConfig.getFeatureFlagConfig(
      FeatureFlagConstant.CONFIG_GOOGLE_IMA
    );
    const imaSettingsFromS3 = enabled ? payload : {};

    if (adResponse && !isAdsUrlPresent) {
      return {
        client: AdClient.IMA,
        imaSettings: {
          adTagUrl: '',
          adResponse: adResponse,
          imaSettingsFromS3: imaSettingsFromS3 ? imaSettingsFromS3 : {}
        }
      };
    } else if (adResponse && isAdsUrlPresent) {
      return {
        client: AdClient.IMA,
        imaSettings: {
          adTagUrl: adResponse,
          adResponse: '',
          imaSettingsFromS3: imaSettingsFromS3 ? imaSettingsFromS3 : {}
        }
      };
    }
  }

  // Code will generate DAI configurations
  getGoogleDaiConfigurations(): AdConfig {
    const tempDisable = true;
    const keyOsDetails = this.singlePlayDetails.keyOsDetails;
    const authToken =
      this.singlePlayDetails.daiDetails &&
      this.singlePlayDetails.daiDetails.auth_token
        ? this.singlePlayDetails.daiDetails.auth_token
        : '';
    let assetKey = '';
    // get the DRM protected asset key if the content is DRM protected
    if (keyOsDetails.nl && keyOsDetails.sdrm && !tempDisable) {
      const browserType = getBrowserType();
      if (browserType === 'Safari') {
        if (
          keyOsDetails.hls_drm_asset_key &&
          keyOsDetails.hls_drm_asset_key != ''
        )
          assetKey = keyOsDetails.hls_drm_asset_key;
      } else {
        if (
          keyOsDetails.dash_drm_asset_key &&
          keyOsDetails.dash_drm_asset_key != ''
        )
          assetKey = keyOsDetails.dash_drm_asset_key;
      }
    } else if (keyOsDetails.asset_key) {
      assetKey = keyOsDetails.asset_key;
    } else {
      return null;
    }

    return {
      client: AdClient.DAI,
      daiSettings: {
        contentSourceId: '',
        assetKey: assetKey,
        isLiveStream: true,
        videoId: '',
        authToken: authToken
      }
    };
  }

  generateSubtitleTracks(subs: SubtitleURL[], activeLanguageTrack: string) {
    const tracks: TextTrack[] = [];
    let languageMatch = '';
    if (getBrowserType() !== 'Safari') {
      let subtitlePreference: { [key: string]: string } | null = null;
      const storedSubtitlePreference =
        localStorage.getItem('subtitlePreference');
      if (storedSubtitlePreference !== null) {
        // Check if the value exists in localStorage
        try {
          subtitlePreference = JSON.parse(storedSubtitlePreference);
          if (subtitlePreference && subtitlePreference[activeLanguageTrack]) {
            languageMatch = subtitlePreference[activeLanguageTrack];
          }
        } catch (error) {
          console.error('Error parsing subtitlePreference:', error); // Handle parsing error
        }
      }
    } else {
      languageMatch = sessionStorage.getItem('subtitleName') || '';
    }
    if (subs.length > 0) {
      subs.forEach((sub) => {
        const track: TextTrack = {};
        track.kind = TextTrackKind.subtitle;
        track.src = sub.url;
        if (languageMatch && languageMatch === sub.language) {
          track.mode = 'showing';
        }
        track.label = getLanguageName(sub.language);
        track.srclang = sub.language;
        tracks.push(track);
      });
    }
    return tracks;
  }

  eventHandler(videoPlayer): void {
    registerEvents(this, this.isPopUpVideo);
    if (!this.isPlayerEventRegistered) {
      this.isPlayerEventRegistered = true;
      // passing reference of player object
      //adCuePoints
      // eslint-disable-next-line
      const that = this;
      let timeUpdateConviFlag = true;
      this.videoPlayer.on(Events.TIME_UPDATE, function (videoPlayer) {
        if (timeUpdateConviFlag) {
          timeUpdateConviFlag = false;
        }
        if (
          !that.isAdPlaying &&
          !(
            that.playableData.asset_subtype === 'sports_vod' ||
            that.playableData.asset_subtype === 'live_event'
          )
        ) {
          const companionAdContainer = document.querySelector('.adContainer');
          if (companionAdContainer) {
            companionAdContainer.style.display = 'none';
          }
          const companionAd = document.querySelector('.companionAdATF');
          if (companionAd) {
            companionAd.classList.remove('companionAdBanner');
          }
        }
        onTimeUpdateEventHelper(videoPlayer);
        const playableData = that.playableData;
        let playerCurrentTime = 0;
        try {
          playerCurrentTime = videoPlayer.currentTime();
        } catch (error) {
          console.log('player current time get error');
        }
        //Content Descriptors
        if (
          playerCurrentTime > 4 &&
          that.contentDescriptorHidden &&
          !that.isAdPlaying
          // commenting below for NOC-12846
          // &&
          // playableData.asset_subtype !== 'trailer' &&
          // playableData.asset_subtype !== 'promo'
        ) {
          that.contentDescriptorHidden = false;
          showContentDescriptor(that.playerConfig);
        }
        if (window.navigator && !window.navigator.onLine) {
          showErrorMessage(getFormattedErrorCode(ErrorCode.NETWORK));
          videoPlayer.pause();
          if (window.navigator && window.navigator.connection) {
            window.navigator.connection.onchange = () => {
              if (
                window.navigator &&
                window.navigator.onLine &&
                document.querySelector('.main-container')
              ) {
                document.querySelector('.main-container').remove();
                videoPlayer.play();
              }
            };
          } else {
            const triggerNetworkTimer = setInterval(function () {
              if (
                window.navigator &&
                window.navigator.onLine &&
                document.querySelector('.main-container')
              ) {
                document.querySelector('.main-container').remove();
                videoPlayer.play();
                clearInterval(triggerNetworkTimer);
              }
            }, 100);
          }
        }
        // add go live first time
        if (that.isSportsLive && videoPlayer.liveTracker.isLive()) {
          if (
            videoPlayer.liveTracker.behindLiveEdge() &&
            document.getElementsByClassName('go-live-button').length === 0
          ) {
            addGoLiveButton(videoPlayer, () => {
              videoPlayer.liveTracker.seekToLiveEdge();
              that.analyticsManager.reportEvent(
                playerEventsConstants.goLiveEvent,
                { element: 'GO LIVE' }
              );
            });
          } // toggle below
          else if (
            !videoPlayer.liveTracker.behindLiveEdge() &&
            !(document.getElementsByClassName('go-live-button').length === 0)
          ) {
            const goLiveButtonContainer =
              document.querySelector('.go-live-button');
            if (goLiveButtonContainer) {
              goLiveButtonContainer.style.display = 'none';
            }
          } else if (
            videoPlayer.liveTracker.behindLiveEdge() &&
            !(document.getElementsByClassName('go-live-button').length === 0)
          ) {
            const goLiveButtonContainer =
              document.querySelector('.go-live-button');
            if (goLiveButtonContainer) {
              goLiveButtonContainer.style.display = 'block';
            }
          }
        }
      });

      this.videoPlayer.on(Events.META_DATA_LOADED, function (videoPlayer: any) {
        adCuePoints(videoPlayer, that.singlePlayDetails);
        if (that.isSportsLive && !getLocalStorage('prefferedAudioStream')) {
          createLanguageSelectionUnit(videoPlayer);
        }
        if (that.isSportsLive) {
          const {
            assetDetails: { audio_languages: audioLanguages = [] } = {}
          } = that.singlePlayDetails || {};
          that.videoPlayer.modifyAudioTracksLabel(
            audioLanguages,
            that.singlePlayDetails.keyOsDetails.stream_language
          );
          convivaAnalyticsTracker.updateConvivaInfo({
            audioLanguage: getLanguageName(
              that.singlePlayDetails.keyOsDetails.stream_language
            )
          });
        } else {
          // moved audiotrachLabel fn call here
          that.videoPlayer.modifyAudioTracksLabel();
        }
        const singlePlayDetails = that.playableData;
        if (
          !that.metaDataLoaded &&
          singlePlayDetails &&
          singlePlayDetails.subtitle_url &&
          singlePlayDetails.subtitle_url.length > 0
        ) {
          const activeLanguageTrack = that.videoPlayer.getActiveLanguageTrack();
          const textTracks = that.generateSubtitleTracks(
            singlePlayDetails.subtitle_url,
            activeLanguageTrack
          );
          if (textTracks.length > 0) that.videoPlayer.addTextTracks(textTracks);
        }
        const getCurrentTime = that.getContentCurrentTime();
        that.videoPlayer.videoPlayer.currentTime(getCurrentTime);
        that.metaDataLoaded = true;
        // VTT thumbnails implementation
        // plugin function is imported by importing the module 'videojs-vtt-thumbnails'
        // this is attached to videoplayer
        // source path is passed
        const srcVtt =
          that.playerConfig.singlePlayDetails.assetDetails.vtt_thumbnail_url ||
          '';
        that.videoPlayer.setVttThumbnailSrc(srcVtt);
        const spapirequestTimeStamp = that.createSpapiRequestTimeStampPayload(
          that.singlePlayDetails
        );
        that.sendConvivaReportEvent(spapirequestTimeStamp);
        const spapiResponseTimeStamp =
          that.createSpapiResponsetTimeStampPayload(that.singlePlayDetails);
        that.sendConvivaReportEvent(spapiResponseTimeStamp);
      });

      this.videoPlayer.on(Events.SEEKED, (videoPlayer: any, seekDetails) => {
        if (window['isCastConnected']) {
          window['seekingSenderBar'] = true;
          seekReceiverWithSeekedSender(videoPlayer);
        }
        onSeekSrubEventHelper(seekDetails);
      });

      this.videoPlayer.on(Events.VOLUME_CHANGE, (videoPlayer: any) => {
        onVolumeChange(videoPlayer);
        if (window['isCastConnected']) {
          setReceiverVolume(videoPlayer);
        }
      });

      this.videoPlayer.on(Events.READY, function (videoPlayer: any) {
        detectChromeCast();
        displayScoreCard(that.playerConfig);
        displayWatchAndWin(that.playerConfig);
        detectChromeCast();
        if (that.isPopUpVideo) {
          registerEvents(that, that.isPopUpVideo);
          convivaSessionKeyMomentsOnReady(videoPlayer);
        } else {
          const { enabled, payload } = that.playerConfig.getFeatureFlagConfig(
            FeatureFlagConstant.CONFIG_PLAYER_RELOAD
          );

          window.enableReloadPlayerOnTimeout = enabled
            ? payload.ENABLEPLAYERRELOADTIMER
            : false;

          if (window.enableReloadPlayerOnTimeout) {
            (function pageReloader() {
              window.playerSeconds = 0;
              window.clearPlayerReloadTimer = that.clearPlayerReloadTimer;

              const reloadPlayerTimeoutInSecs =
                payload && payload.PLAYERSECONDS ? payload.PLAYERSECONDS : 30;
              const reloadPlayerRetryCount =
                payload && payload.RELOADCOUNT ? payload.RELOADCOUNT : 2;

              that.incrementPlayerCounter = () => {
                if (
                  window.playerSeconds > reloadPlayerTimeoutInSecs &&
                  window.isPlayingEventSent === false &&
                  document.getElementById('video-tag-tobaccoAdvisory') === null
                ) {
                  if (that.reloadCount < reloadPlayerRetryCount) {
                    that.reloadCount += 1;
                    that.reloadPlayer();
                  }
                }
                if (
                  that.reloadCount < reloadPlayerRetryCount &&
                  that.isAdPlaying === false
                ) {
                  window.playerSeconds = window.playerSeconds + 1;
                  window.playerReloadTimer = setTimeout(
                    that.incrementPlayerCounter,
                    1000
                  );
                }
              };
              that.incrementPlayerCounter();
            })();
            // pageReloader();
          }
          convivaSessionOnReady(videoPlayer);
        }
        if (
          that.playerConfig.callConsumptionPageApis &&
          !that.validContentTypesForStaleApi.includes(
            that.playerConfig.contentType
          )
        ) {
          that.playerConfig.callConsumptionPageApis();
        }
      });
      // time between Ready and Play is the VST
      this.videoPlayer.on(Events.PLAY, function (videoPlayer) {
        if (
          that.hasTobaccoAdvisoryVideo &&
          that.showTobaccoAdvisoryWithoutPreRoll &&
          !that.preRollTobaccoAdvisoryVideoShown
        ) {
          that.showTobaccoAdvisoryWithoutPreRoll = false;
          that.preRollTobaccoAdvisoryVideoShown = true;
          showTobaccoAdvisoryPreRollVideo();
        }
        if (localStorage.getItem('resumeLiveStream') === 'true') {
          registerEvents(that);
          localStorage.removeItem('resumeLiveStream');
          window.convivaAnalytic.reportEvent(
            window.Conviva.Constants.Events.USER_WAIT_ENDED
          );
          // convivaSessionOnReady(videoPlayer);
        }

        // x free minutes handling
        if (that.isSportsLive) {
          toggleKeyMoments(true);
          that.playerConfig.updateShowReactions(true);
          const waterMark = document.querySelector('.watermarkContainer');
          waterMark ? (waterMark.style.display = 'block') : '';
          if (that.playerConfig.userType !== 'premium') {
            // setting freeAvailableSeconds to default from config
            if (
              that.playerConfig &&
              that.playerConfig.xmins_free_config &&
              that.playerConfig.xmins_free_config.live_event &&
              that.playerConfig.xmins_free_config.live_event.free_period_in_mins
            ) {
              that.freeAvailableSeconds =
                parseInt(
                  that.playerConfig.xmins_free_config.live_event
                    .free_period_in_mins
                ) * 60;
              that.showFreeMinutesTimer = true;
            } else {
              that.showFreeMinutesTimer = false;
              // that.freeMinutesTimer = {};
            }

            // update freeAvailableSeconds if available in local storage
            const frMinsFromStorage = getFreeMinutes(
              that.playerConfig.contentId
            );
            if (frMinsFromStorage) {
              that.freeAvailableSeconds = getFreeMinutes(
                that.playerConfig.contentId
              );
            } else if (frMinsFromStorage === 0) {
              that.freeAvailableSeconds = getFreeMinutes(
                that.playerConfig.contentId
              );
            }

            that.checkFreeMinutesPopup();

            // timer for calculating free minutes available
            const freeMinutesInfoContainer = document.querySelector(
              '.freeMinutesInfoTimer'
            );
            if (
              that.showFreeMinutesTimer &&
              !freeMinutesInfoContainer &&
              that.freeAvailableSeconds > 0
            ) {
              that.playerConfig.showFreeMinutes({
                playerRef: that,
                timeRemaining: that.freeAvailableSeconds
              });
            }

            // timer for calculating free minutes available
            // if (!that.freeMinutesTimer && that.showFreeMinutesTimer) {
            //   that.freeMinutesTimer = setInterval(() => {
            //     that.freeAvailableSeconds = that.freeAvailableSeconds - 1;
            //     //free minutes ended for user
            //     that.checkFreeMinutesPopup();
            //     setFreeMinutes(
            //       that.playerConfig.contentId,
            //       that.freeAvailableSeconds
            //     );
            //     updateFreeMinutes(that.freeAvailableSeconds);
            //   }, 1000);
            // }
          }
          // free minutes ends

          // watermark related handling
          const watermarkContainer = document.querySelector(
            '.watermarkContainer'
          );
          if (!watermarkContainer) {
            let concurrentTimer = 30;

            const { enabled, payload } = that.playerConfig.getFeatureFlagConfig(
              FeatureFlagConstant.CONFIG_SPORTS
            );
            const offersDataForSports = enabled ? payload : {};
            if (offersDataForSports?.concurrent_users_interval) {
              concurrentTimer = offersDataForSports.concurrent_users_interval;
            } else {
              concurrentTimer = 30;
            }

            addWaterMark(videoPlayer);
            if (!that.concurrentUsersTimer) {
              fetchConcurrentUsers(that.playableData, that.playerConfig);
              that.concurrentUsersTimer = setTimeout(function myTimer() {
                fetchConcurrentUsers(that.playableData, that.playerConfig);
                that.concurrentUsersTimer = setTimeout(
                  myTimer,
                  concurrentTimer * 1000
                );
              }, concurrentTimer * 1000);
            }
          }
          // watermark handling ends
          // remove live edge button only for sports live
          const seekLiveControlContainer = document.querySelector(
            '.vjs-seek-to-live-control'
          );
          if (seekLiveControlContainer) {
            seekLiveControlContainer.style.display = 'none';
          }
        }

        const { entitlement } = that.singlePlayDetails || {};
        const { isLive } = entitlement;
        if (isLive) {
          const timeDivider = document.querySelector('.vjs-time-divider');
          if (timeDivider) {
            timeDivider.style.display = 'none';
          }
          const DurationDisplayTimer = document.querySelector('.vjs-duration');
          if (DurationDisplayTimer) {
            DurationDisplayTimer.style.display = 'none';
          }
          const currentTimer = document.querySelector('.vjs-current-time');
          if (currentTimer) {
            currentTimer.style.marginRight = 'auto';
          }
          if (!that.isSportsLive) {
            if (currentTimer) {
              currentTimer.style.display = 'none';
            }
          }
        }

        if (that.isSportsLive) {
          const liveButtonContainer =
            document.querySelector('.vjs-live-control');
          if (liveButtonContainer) {
            liveButtonContainer.style.display = 'none';
          }
        }
        detectChromeCast();
        onPlayEventHelper(videoPlayer, that.isPopUpVideo);

        if (
          that.isNewsCatchup &&
          document.getElementsByClassName('go-live-button').length === 0
        ) {
          addGoLiveButton(videoPlayer, that.playerConfig.goLiveCallback);
        }
        const mandatoryPopupContainer =
          document.querySelector('.main-container');
        if (mandatoryPopupContainer && mandatoryPopupContainer !== null) {
          that.videoPlayer.pause();
        }
        that.playerConfig.clearSubscriptionNudgePopupTimer();
      });

      this.videoPlayer.on(Events.PAUSE, function (videoPlayer: any) {
        onPauseEventHelper(videoPlayer);
        if (that.isSportsLive) {
          that.clearFreeSecondsTimer();
        }
        if (!this.isStaleApisCalled) {
          setTimeout(() => {
            if (that.playerConfig && that.playerConfig.callStaledApis) {
              that.playerConfig.callStaledApis();
              this.isStaleApisCalled = true;
            }
          }, 3000);
        }
        // Local Storage used for storing number of count and expiry time
        if (
          that.playerConfig.shouldSubscriptionNudgeDisplayed({
            nudgeType: 'popup',
            playerRef: that
          })
        ) {
          const {
            consumption_paused_nudge_wait_duration: pauseDuration = 10,
            consumption_nudge_frequency: popupFrequency = 3
          } =
            that.playerConfig.getAvodNudgeConfig(
              that.playerConfig.remoteConfig
            ) || [];
          let AvodNudgeCount: any = getLocalStorage('AvodNudgeCount');
          const expirydte: any = getLocalStorage('AvodNudgeExpDate');
          let AvodSessionCount: any = getSessionStorage('AvodSessionCount');
          const originalDate: any = new Date();
          const now: any = `${originalDate.getFullYear()}${originalDate.getMonth()}${originalDate.getDate()}`;
          if (!AvodNudgeCount) {
            AvodNudgeCount = '0';
            setLocalStorage('AvodNudgeCount', AvodNudgeCount);
            setLocalStorage(
              'AvodNudgeExpDate',
              `${originalDate.getFullYear()}${originalDate.getMonth()}${originalDate.getDate()}`
            );
          }
          if (!AvodSessionCount) {
            AvodSessionCount = '0';
            setSessionStorage('AvodSessionCount', '0');
          }
          if (
            parseInt(AvodNudgeCount) > 0 &&
            parseInt(now) != parseInt(expirydte)
          ) {
            AvodNudgeCount = '0';
            setLocalStorage('AvodNudgeCount', AvodNudgeCount);
            setLocalStorage(
              'AvodNudgeExpDate',
              `${originalDate.getFullYear()}${originalDate.getMonth()}${originalDate.getDate()}`
            );
          }
          if (
            parseInt(AvodNudgeCount) < popupFrequency &&
            parseInt(AvodSessionCount) < 1
          ) {
            window.avodNudgeHandler = setTimeout(() => {
              if (
                !document.querySelector('#main-container') &&
                !document.querySelector('.subscriptionPopupCompWrap') &&
                !document.querySelector('.drowerPopupContent') &&
                !that.isAdPlaying
              ) {
                that.playerConfig.showSubscriptionNudge(
                  videoPlayer,
                  that.playerConfig
                );
                setLocalStorage(
                  'AvodNudgeCount',
                  `${parseInt(AvodNudgeCount) + 1}`
                );
                setSessionStorage(
                  'AvodSessionCount',
                  `${parseInt(AvodSessionCount) + 1}`
                );
              }
            }, pauseDuration * 1000);
          }
        }
      });
      this.videoPlayer.on(Events.ENDED, function (videoPlayer) {
        clearInterval(intervalTimerRefForPinScoreCard);
        removeContinueWatchAfterPlaybackEnd(
          that.playerConfig,
          that.singlePlayDetails
        );
        triggerWatchHistory(videoPlayer); // AT END of the play
        if (that.isPopUpVideo && that.playerConfig.closeKeyMomentsPopup) {
          that.dispose();
          that.playerConfig.closeKeyMomentsPopup();
          convivaAnalyticsTracker.reportPlaybackEndedConviva();
        } else {
          onPlayEndedEventHelper(videoPlayer);
        }
      });

      this.videoPlayer.on(Events.FULL_SCREEN_CHANGE, function (videoPlayer) {
        fullScreenEvent(videoPlayer);
        displayWatchAndWin(that.playerConfig);
        if (that.playerConfig.checkIsFullScreen) {
          that.playerConfig.checkIsFullScreen();
        }
      });

      this.videoPlayer.on(Events.SKIPINTRO, function (videoPlayer) {
        console.log('Skip Intro');
      });

      this.videoPlayer.on(
        'audioChanged',
        function (videoPlayer: any, audioLanguage: any) {
          if (that.isSportsLive) {
            that.playerConfig.updateShowReactions(false);
            audioChange(
              audioLanguage,
              that.isSportsLive,
              that.singlePlayDetails.keyOsDetails.stream_language
            );
          } else {
            audioChange(audioLanguage, that.isSportsLive);
            // SubtitlePreference when browser is not Safari

            if (getBrowserType() !== 'Safari') {
              const activeLanguageTrack =
                that.videoPlayer.getActiveLanguageTrack();
              const textTracks = that.generateSubtitleTracks(
                that.singlePlayDetails.assetDetails.subtitle_url,
                activeLanguageTrack
              );
              if (textTracks.length > 0) {
                that.videoPlayer.applySubtitleChangesOnAudioChange(
                  textTracks,
                  audioLanguage
                );
              }
            }
          }
        }
      );

      this.videoPlayer.on(
        Events.QUALITY_CHANGE,
        function (selectedQuality, videoPlayer) {
          if (
            selectedQuality === getSelectedQualityValue(window.selectedLabel)
          ) {
            window.selectedLabel = '';
            videoQualityChange(selectedQuality, videoPlayer);
          }
        }
      );

      this.videoPlayer.on(Events.SUBTITLECHANGE, function (videoPlayer) {
        subtitleChange(playerEventsConstants.subTitleChange, videoPlayer);
        const label = getActiveSubtitles(videoPlayer);
        const adContainer = document.getElementById(
          'video-tag_ima-ad-container'
        );
        if (adContainer && adContainer.style.display === 'block') {
          // ad is triggering SUBTITLE change event
          return;
        } else {
          if (getBrowserType() !== 'Safari') {
            const activeLanguageTrack =
              that.videoPlayer.getActiveLanguageTrack();
            let subtitlePreference =
              localStorage.getItem('subtitlePreference') || {};
            // sessionStorage.setItem('subtitleName', label);
            if (activeLanguageTrack) {
              if (typeof subtitlePreference === 'string') {
                subtitlePreference = JSON.parse(subtitlePreference);
              }
              subtitlePreference[activeLanguageTrack] = label;
              setLocalStorage('subtitlePreference', subtitlePreference);
            }
          } else {
            sessionStorage.setItem('subtitleName', label);
          }
          if (window['isCastConnected']) {
            textTracksChange(label, that);
          }
        }
      });

      this.videoPlayer.on(Events.PROGRESS, function (videoPlayer) {
        if (videoPlayer.currentTime() > 0) {
          that.firstPlay = true;
        }
        playerProgress(videoPlayer);
        if (!that.isPopUpVideo) {
          triggerWatchHistory(videoPlayer);
          handleStaleApiCall(videoPlayer);
        }
      });

      this.videoPlayer.on(Events.ERROR, function (error) {
        fetchSelfErrors(that.playerConfig);
        if (that.playerConfig && that.playerConfig.callStaledApis) {
          that.playerConfig.callStaledApis();
        }
        // let { code: errorCode = 'N/A', message: errorMessage = 'N/A' } = error;
        const { code: errorCode = 'N/A' } = error;
        let { message: errorMessage = 'N/A' } = error;
        if (
          errorCode.toString() ===
          ErrorCode.MEDIA_ERR_SRC_NOT_SUPPORTED.toString()
        ) {
          errorMessage = 'MEDIA_ERR_SRC_NOT_SUPPORTED';
          deleteLocalStorage('licenseBuffer');
        } else if (errorCode.toString() === ErrorCode.TIMEOUT.toString()) {
          errorMessage = 'TIMEOUT';
        } else if (
          errorCode.toString() === ErrorCode.UNSUPPORTED_SCHEME.toString()
        ) {
          errorMessage = 'UNSUPPORTED_SCHEME';
        } else if (
          errorCode.toString() === ErrorCode.BAD_HTTP_STATUS.toString()
        ) {
          errorMessage = 'BAD_HTTP_STATUS';
        } else if (errorCode.toString() === ErrorCode.HTTP_ERROR.toString()) {
          errorMessage = 'HTTP_ERROR';
        } else if (errorCode.toString() === ErrorCode.NETWORK.toString()) {
          errorMessage = 'NETWORK';
        } else if (
          errorCode.toString() === ErrorCode.MEDIAKEYSYSTEMS.toString()
        ) {
          errorMessage = 'MEDIAKEYSYSTEMS';
          // deleting  existing license  due to license not validating by player or
          // MediaKeySession error when license corrupted or expired
          deleteLocalStorage('licenseBuffer');
        }
        if (!that.isPlayingClearContent) {
          that.dai_sports_error = false;
          convivaAnalyticsTracker.updateConvivaInfo({
            infoMessage: JSON.stringify({
              errorCode: `${errorCode}`,
              errorMessage: `${errorMessage}`
            })
          });
          const clearMedia = that.getClearMedia();
          if (clearMedia) {
            const eventPayload = {
              type: 'INITIALIZE_CONTENT_FALLBACK',
              error: JSON.stringify(error)
            };
            convivaAnalyticsTracker.sendConvivaReportEvent(eventPayload);
            convivaAnalyticsTracker.convivaSessionWaitStarted();
            that.initializeFallbackPlayback();
          } else {
            const eventPayload = {
              type: 'ON_ERROR_HELPER_AND_IS_NOT_CLEAR_MEDIA',
              error: JSON.stringify(error)
            };
            convivaAnalyticsTracker.sendConvivaReportEvent(eventPayload);
            onErrorHelper(error, videoPlayer, that.dai_sports_error);
          }
          if (window) {
            window.drmSessionError = true;
          }
        } else {
          const eventPayload = {
            type: 'ON_ERROR_HELPER',
            error: JSON.stringify(error)
          };
          convivaAnalyticsTracker.sendConvivaReportEvent(eventPayload);
          that.analyticsManager.reportEvent(
            playerEventsConstants.ILT_ERROR_FALLBACK,
            {}
          );
          // live sports fallback to clear hls
          if (that.isSportsLive && that.sportsLiveRetryCount < 3) {
            that.sportsLiveRetryCount++;
            that.dai_sports_error = true;
            setTimeout(() => {
              that.initializeFallbackPlayback();
            }, 2000);
          }
          if (that.sportsLiveRetryCount == 2) {
            that.dai_sports_error = false;
          }
          onErrorHelper(error, videoPlayer, that.dai_sports_error);
        }
        if (
          that.playerConfig.callConsumptionPageApis &&
          !that.validContentTypesForStaleApi.includes(
            that.playerConfig.contentType
          )
        ) {
          that.playerConfig.callConsumptionPageApis();
        }
      });

      // this.videoPlayer.on(Events.DISPOSE, function (error) {
      //   that.videoPlayer = null;
      //   that.isDai = false;
      // });

      let displayAd = false;

      this.videoPlayer.on(AdEvents.AD_STARTED, function () {
        const scoreMainContainer = document.querySelector(
          '#scoreMainContainer'
        ) as HTMLElement | null;
        if (scoreMainContainer) {
          if (scoreMainContainer.style.display === 'none') {
            displayAd = false;
          } else if (scoreMainContainer.style.display === 'flex') {
            scoreMainContainer.style.display = 'none';
            displayAd = true;
          }
        }
        const companionAdContainer = document.querySelector('.adContainer');
        if (companionAdContainer) {
          companionAdContainer.style.display = 'block';
        }
        that.isAdPlaying = true;
        checkAdsInterruptionThreshold(that.playerConfig, true);
        if (window && window.chromePopupDisplayed) {
          that.videoPlayer.pause();
        }
        if (that.isSportsLive) {
          toggleKeyMoments(false);
          that.playerConfig.updateShowReactions(false);
          const waterMark = document.querySelector('.watermarkContainer');
          waterMark ? (waterMark.style.display = 'none') : '';
        }
        showIsTrailer(false);
        toggleSubscribeTeaserBanner(false);
      });

      this.videoPlayer.on(AdEvents.AD_COMPLETE, function () {
        const scoreMainContainer = document.querySelector(
          '#scoreMainContainer'
        ) as HTMLElement | null;
        if (scoreMainContainer) {
          if (displayAd === true) {
            scoreMainContainer.style.display = 'flex';
            displayAd = false;
          }
        }
        that.isAdPlaying = false;
        checkAdsInterruptionThreshold(that.playerConfig, false);
        if (
          that.hasTobaccoAdvisoryVideo &&
          !that.preRollTobaccoAdvisoryVideoShown
        ) {
          that.preRollTobaccoAdvisoryVideoShown = true;
          showTobaccoAdvisoryPreRollVideo();
        }
        const companionAdContainer = document.querySelector('.adContainer');
        if (companionAdContainer) {
          companionAdContainer.style.display = 'none';
        }
        if (that.isSportsLive) {
          toggleKeyMoments(true);
          that.playerConfig.updateShowReactions(true);
          const waterMark = document.querySelector('.watermarkContainer');
          waterMark ? (waterMark.style.display = 'block') : '';
        }
        const companionAd = document.querySelector('.companionAdATF');
        if (companionAd) {
          companionAd.classList.remove('companionAdBanner');
        }
        showIsTrailer(true);
        const continueWatch = document.getElementById('continue-div');
        if (continueWatch) {
          toggleSubscribeTeaserBanner(false);
        } else {
          toggleSubscribeTeaserBanner(true);
        }
      });

      this.videoPlayer.on(AdEvents.PRE_ROLL_COMPLETED, function () {
        that.isAdPlaying = false;
        checkAdsInterruptionThreshold(that.playerConfig, false);
        if (!that.getPlayerPopupStatus()) {
          if (that.videoPlayer.pause) that.videoPlayer.pause();
          triggerMandatoryPopup(that.videoPlayer, that.playerConfig);
        } else if (that.checkToTriggerSubscriptionNudge()) {
          that.triggerSubscriptionNudge();
        } else if (
          that.hasTobaccoAdvisoryVideo &&
          !that.preRollTobaccoAdvisoryVideoShown
        ) {
          that.preRollTobaccoAdvisoryVideoShown = true;
          showTobaccoAdvisoryPreRollVideo();
        }
        if (
          that.preRollForDaiConfig &&
          that.videoPlayer &&
          that.videoPlayer.destroy
        ) {
          window.drmSessionError = true;
          that.initializePlaybackType();
        }
        that.preRollDetectedorFailed = true;
        setTimeout(
          () => reportVideoViewEvent(that.singlePlayDetails.assetDetails.id),
          1000
        );
        setTimeout(
          () =>
            updateVideoResolutionTag(
              'Update quality and subTitle Change after  PRE_ROLL_COMPLETED'
            ),
          3000
        );
      });

      this.videoPlayer.on(
        AdEvents.AD_BLOCKER_DETECTED,
        function (videoPlayer: any) {
          that.isAdPlaying = false;
          checkAdsInterruptionThreshold(that.playerConfig, false);
          if (!that.getPlayerPopupStatus()) {
            if (that.videoPlayer.pause) that.videoPlayer.pause();
            triggerMandatoryPopup(that.videoPlayer, that.playerConfig);
          } else if (that.checkToTriggerSubscriptionNudge()) {
            that.triggerSubscriptionNudge();
          } else if (
            that.hasTobaccoAdvisoryVideo &&
            !that.preRollTobaccoAdvisoryVideoShown
          ) {
            that.preRollTobaccoAdvisoryVideoShown = true;
            showTobaccoAdvisoryPreRollVideo();
          }
          that.preRollDetectedorFailed = true;
          setTimeout(
            () =>
              updateVideoResolutionTag(
                'Update quality and subTitle Change after AD_BLOCKER_DETECTED'
              ),
            3000
          );
        }
      );

      this.videoPlayer.on(
        AdEvents.AD_ERROR,
        function (adError: { code: number }) {
          that.isAdPlaying = false;
          checkAdsInterruptionThreshold(that.playerConfig, false);
          if (
            document.querySelector('.video-js') &&
            document
              .querySelector('.video-js')
              .classList.contains('vjs-ad-loading')
          ) {
            document
              .querySelector('.video-js')
              .classList.remove('vjs-ad-loading');
          }
          if (!that.mandatoryVideoCountUpdated && adError.code === 9000) {
            if (!that.getPlayerPopupStatus()) {
              if (that.videoPlayer.pause) that.videoPlayer.pause();
              triggerMandatoryPopup(that.videoPlayer, that.playerConfig);
            } else if (that.checkToTriggerSubscriptionNudge()) {
              that.triggerSubscriptionNudge();
            } else if (
              that.hasTobaccoAdvisoryVideo &&
              !that.preRollTobaccoAdvisoryVideoShown
            ) {
              that.preRollTobaccoAdvisoryVideoShown = true;
              showTobaccoAdvisoryPreRollVideo();
            }
            that.preRollDetectedorFailed = true;
          }
          if (window.isLive) {
            if (
              that.preRollForDaiConfig &&
              that.videoPlayer &&
              that.videoPlayer.destroy
            ) {
              that.videoPlayer.destroy();
              that.initializePlaybackType();
            }
          }
        }
      );

      this.videoPlayer.on(playerEventsConstants.qualityBtnClicked, () => {
        this.analyticsManager.reportEvent(playerEventsConstants.playerCtas, {});
      });

      this.videoPlayer.on(
        playerEventsConstants.audioBtnClicked,
        (videoPlayer: any) => {
          const eventPayLoad = {
            element: 'Audio Change'
          };
          this.analyticsManager.reportEvent(
            playerEventsConstants.playerCtas,
            eventPayLoad
          );
        }
      );

      this.videoPlayer.on(
        playerEventsConstants.muteBtnClicked,
        (videoPlayer: { muted: () => any }) => {
          onmuteChange(videoPlayer);
          const eventPayLoad = {
            muteValue: videoPlayer.muted()
          };
          this.analyticsManager.reportEvent(
            playerEventsConstants.muteChanged,
            eventPayLoad
          );
        }
      );

      this.videoPlayer.on(
        playerEventsConstants.subtitleBtnClicked,
        (videoPlayer: any) => {
          const eventPayLoad = {
            element: 'Subtitle Change'
          };
          subtitleBtnEvent();
          this.analyticsManager.reportEvent(
            playerEventsConstants.playerCtas,
            eventPayLoad
          );
        }
      );
    }
  }

  setRecoDetails(recoData: any[]) {
    this.similarFromReco = recoData;
    // to add reco skip button on popups if reco data is not available initially
    triggerRecoSkipButton();
  }

  async checkRecoData() {
    const {
      fetchNextVideos = null,
      fetchPreviousVideos = null,
      similarFromReco = [],
      userType = ''
    } = { ...this.playerConfig };
    if (similarFromReco && similarFromReco.length > 0) {
      this.isRecoPending = false;
      this.setRecoDetails(similarFromReco);
    } else {
      if (
        (fetchNextVideos || fetchPreviousVideos) &&
        this.isRecoPending &&
        this.playableData &&
        Object.keys(this.playableData).length &&
        this.singlePlayDetails &&
        Object.keys(this.singlePlayDetails).length
      ) {
        this.isRecoPending = false;
        const getRecommendedData =
          this.playableData &&
          this.playableData.asset_subtype &&
          (this.playableData.asset_subtype.indexOf('episode') >= 0 ||
            this.playableData.asset_subtype === 'sports_vod')
            ? await fetchPreviousVideos({ playerRef: this })
            : await fetchNextVideos({ contentId: this.playableData.id });
        if (getRecommendedData && getRecommendedData.items) {
          this.setRecoDetails(getRecommendedData.items);
        } else if (getRecommendedData && getRecommendedData.error_code) {
          this.isRecoPending = true;
        }
      }
    }
    if (
      this.playableData?.business_type === 'free_downloadable' &&
      this.singlePlayDetails?.showDetails?.business_type ===
        'premium_downloadable' &&
      userType === 'guest'
    ) {
      this.setRecoDetails([]); // DO not show upnext
    }
  }

  // functions called fromm FE
  invokePlayer() {
    if (
      typeof window !== 'undefined' &&
      document.querySelector('#main-container') != null
    ) {
      (document as any).querySelector('#main-container').remove();
    }
    this.metaDataLoaded = false;
    this.isNewsCatchup = false;
    this.mandatoryVideoCountUpdated = false;
    this.preRollDetectedorFailed = false;
    if (window.translationsAndConfigs) {
      this.checkMediaStartPlayback();
    } else {
      const SCRIPT_ID = 'videojs-locale';
      let script = document.getElementById(SCRIPT_ID);
      const displayLanguage = this.displayLanguage;

      if (!script) {
        script = document.createElement('script');
        script.id = SCRIPT_ID;
        document.head.appendChild(script);
      }

      script.addEventListener(
        'load',
        function () {
          this.translationsAndConfigs =
            typeof window !== 'undefined' && window
              ? window.translationsAndConfigs
              : {};

          this.checkMediaStartPlayback();
        }.bind(this)
      );
      const playerUrl = () => {
        let playerUrlscript;
        if (this.playerConfig.playerScriptUrl) {
          playerUrlscript = this.playerConfig?.playerScriptUrl.substring(
            0,
            this.playerConfig?.playerScriptUrl.lastIndexOf('/')
          );
        } else {
          playerUrlscript = `https://playerscript.zee5.com/zee5Videojs/${VERSION}`;
        }
        return playerUrlscript;
      };
      // script.setAttribute('src', `https://playerscript.zee5.com/zee5Videojs/locale/locale_${displayLanguage || 'en'}.js`);
      script.setAttribute(
        'src',
        `${playerUrl()}/locale/locale_${displayLanguage || 'en'}.js`
      );
      // uncomment below script to load translation files locally to test only, folder should be added inside public->player->and paste locale folder
      // script.setAttribute(
      //   'src',
      //   `/player/locale/locale_${displayLanguage || 'en'}.js`
      // );
    }
  }

  private checkMediaStartPlayback() {
    this.playableData = this.getPlayableData();
    ifAssetIdChanges(this.singlePlayDetails.assetDetails.id);
    this.isSportsLive = this.playerConfig.isSportsLiveEvent({
      assetType: this.playableData?.asset_type,
      assetSubType: this.playableData?.asset_subtype
    });

    window.isSportsLive = this.isSportsLive ? true : false;
    if (this.playerConfig.contentId && this.playableData) {
      if (this.videoPlayer && this.videoPlayer.destroy) {
        this.videoPlayer.destroy();
      }
      this.initPlaybackFlow();
      this.contentDescriptorHidden = true;
    }
    this.watchHistoryHandler();
    this.abrHandler();
  }

  setPlayerConfig(newPlayerConfig: ZeePlayerConfig): void {
    this.playableData = null;
    this.playerConfig = {
      ...this.playerConfig,
      ...newPlayerConfig
    };
    this.setDataFromPlayerConfig();
    this.isRecoPending = true;
    this.preRollForDaiConfig = false;
  }

  updateWrapperConfig(newPlayerConfig: ZeePlayerConfig) {
    this.playerConfig = {
      ...this.playerConfig,
      ...newPlayerConfig
    };
    if (
      (this.playableData &&
        Object.keys(this.playableData).length &&
        this.isRecoPending &&
        (this.playableData.asset_type !== 9 ||
          this.playerConfig.userType === 'premium')) ||
      (this.playerConfig &&
        this.playerConfig.similarFromReco &&
        this.playerConfig.similarFromReco.length > 0)
    ) {
      this.checkRecoData();
    }
  }

  // applySOSCapping(abrCapping: any) {
  //   if (abrCapping) {
  //     const {
  //       asset_type: sosAssetType,
  //       asset_subtype: sosAssetSubtype,
  //       capping: sosCapping
  //     } = abrCapping;
  //     this.sosAssetType = sosAssetType;
  //     this.sosAssetSubtype = sosAssetSubtype;
  //     this.sosCapping = sosCapping;
  //   }
  // }

  dispose() {
    this.playerConfig.removeScorecardWidget();
    clearInterval(intervalTimerRefForPinScoreCard);
    if (this.isAdPlaying) {
      this.analyticsManager.reportEvent(playerEventsConstants.userClosedAd, {});
      this.analyticsManager.reportEvent(
        playerEventsConstants.exitBeforeAdStart,
        {}
      );
    }
    triggerWatchHistory(this.videoPlayer.videoPlayer, true);
    if (this.videoPlayer != null) {
      this.playerDisposedFlag = true;
      document.removeEventListener(
        'visibilitychange',
        this.handleVisibilityChange
      );
      this.deregisterEvents();
      this.videoPlayer.destroy();
      this.isPlayerEventRegistered = false;
    }
    this.playerConfig.clearSubscriptionNudgePopupTimer();
    if (this.concurrentUsersTimer) {
      clearTimeout(this.concurrentUsersTimer);
    }
    if (this.freeMinutesTimer) {
      this.clearFreeSecondsTimer();
    }
  }

  deregisterEvents() {
    this.videoPlayer.removeAllListeners(Events.TIME_UPDATE);
    this.videoPlayer.removeAllListeners(Events.META_DATA_LOADED);
    this.videoPlayer.removeAllListeners(Events.SEEKED);
    this.videoPlayer.removeAllListeners(Events.VOLUME_CHANGE);
    this.videoPlayer.removeAllListeners(Events.READY);
    this.videoPlayer.removeAllListeners(Events.PLAY);
    this.videoPlayer.removeAllListeners(Events.PAUSE);
    this.videoPlayer.removeAllListeners(Events.MUTE);
    this.videoPlayer.removeAllListeners(Events.ENDED);
    this.videoPlayer.removeAllListeners(Events.FULL_SCREEN_CHANGE);
    this.videoPlayer.removeAllListeners(Events.SKIPINTRO);
    this.videoPlayer.removeAllListeners('audioChanged');
    this.videoPlayer.removeAllListeners(Events.QUALITY_CHANGE);
    this.videoPlayer.removeAllListeners(Events.SUBTITLECHANGE);
    this.videoPlayer.removeAllListeners(Events.PROGRESS);
    this.videoPlayer.removeAllListeners(Events.ERROR);
    this.videoPlayer.removeAllListeners(AdEvents.AD_STARTED);
    this.videoPlayer.removeAllListeners(AdEvents.AD_COMPLETE);
    this.videoPlayer.removeAllListeners(AdEvents.AD_ERROR);
    this.videoPlayer.removeAllListeners(AdEvents.PRE_ROLL_COMPLETED);
    this.videoPlayer.removeAllListeners(AdEvents.AD_BLOCKER_DETECTED);
    this.videoPlayer.removeAllListeners(
      playerEventsConstants.qualityBtnClicked
    );
    this.videoPlayer.removeAllListeners(playerEventsConstants.audioBtnClicked);
    this.videoPlayer.removeAllListeners(playerEventsConstants.muteBtnClicked);
    this.videoPlayer.removeAllListeners(
      playerEventsConstants.subtitleBtnClicked
    );
  }

  getPlayerPopupStatus() {
    const { updateMandatoryRegistrationViewCount = null, userType = 'guest' } =
      this.playerConfig || {};
    const { assetDetails = {} } = this.singlePlayDetails || {};
    const { content_qualify_for_mandatory_reg = false } = assetDetails || {};
    const userTypeFlag = userType === 'guest';
    this.mandatoryVideoCountUpdated = true;
    updateMandatoryRegistrationViewCount({ contentData: assetDetails });
    const { enabled, payload } = this.playerConfig.getFeatureFlagConfig(
      FeatureFlagConstant.CONFIG_REGISTRATION_POPUP
    );
    const registrationPopupConfig = enabled ? payload : {};

    const mandatoryRegistrationVideoCount = getLocalStorageInteger(
      'mandatoryRegistrationVideoCount'
    );
    const isBlock =
      mandatoryRegistrationVideoCount &&
      registrationPopupConfig?.popup_frequency &&
      mandatoryRegistrationVideoCount %
        registrationPopupConfig?.popup_frequency ===
        0;
    const popupCount =
      getLocalStorageInteger('skippableRegistrationPopupCount') || 0;
    const isPartner =
      typeof window !== 'undefined' &&
      window &&
      window.location &&
      (window.location.pathname.includes('b2b-consumption') ||
        window.location.pathname.includes('b2b-live-tv'));
    if (
      content_qualify_for_mandatory_reg === true &&
      isBlock &&
      userTypeFlag &&
      !isPartner &&
      registrationPopupConfig?.max_popup_count &&
      popupCount < registrationPopupConfig?.max_popup_count
    ) {
      setLocalStorage(
        'skippableRegistrationPopupCount',
        (popupCount + 1).toString()
      );
      return false;
    }
    return true;
  }

  watchHistoryHandler() {
    const { contentType = '' } = this.playerConfig || {};
    const { watchHistoryDetails } = this.singlePlayDetails || {};
    if (contentType && contentType !== 'livetv' && this.playableData) {
      if (watchHistoryDetails && watchHistoryDetails.played_duration) {
        this.setContentCurrentTime(watchHistoryDetails.played_duration);
      } else {
        this.setContentCurrentTime(0);
      }
    }
  }

  setContentCurrentTime(watchTime: number) {
    if (this.playableData && Object.keys(this.playableData).length) {
      this.playableData.watchTime = watchTime;
    }
  }

  getContentCurrentTime() {
    const { clipOffset = 0, primaryMediaId = '' } = this.playerConfig || {};
    const startOffset = clipOffset ? parseInt(clipOffset, 10) : clipOffset;
    let videoStartTime = 0;
    if (
      this.playableData &&
      Object.keys(this.playableData).length &&
      this.playableData.watchTime
    ) {
      videoStartTime = Math.round(this.playableData.watchTime);
    }
    if (primaryMediaId === '' && startOffset) {
      videoStartTime = startOffset;
    }
    return videoStartTime;
  }

  handleVisibilityChange() {
    if (document['hidden']) {
      console.log('visibility hidden');
      this.isVisible = false;
      if (window && window.Conviva && window.Conviva.Analytics) {
        window.Conviva.Analytics.reportAppBackgrounded();
        window.convivaAnalytic.reportEvent(
          window.Conviva.Constants.Events.USER_WAIT_STARTED
        );
      }
    } else {
      console.log('visibility');
      this.isVisible = true;
      if (window && window.Conviva && window.Conviva.Analytics) {
        window.Conviva.Analytics.reportAppForegrounded();
        window.convivaAnalytic.reportEvent(
          window.Conviva.Constants.Events.USER_WAIT_ENDED
        );
      }
      if (this.setPlayerHaltedFlag) {
        this.setPlayerHaltedFlag = false;
        if (this.videoPlayer && this.isPlayingClearContent) {
          console.log('visibility ss');
          this.videoPlayer.setup(this.defaultPlayerConfig, this.mediaObject);
        } else {
          setTimeout(() => {
            console.log('reloading for DRM content');
            window.location.reload();
          }, 1000);
        }
      }
    }
  }

  handleOrientationChange() {
    displayWatchAndWin(this.playerConfig);
  }

  sendConvivaReportEvent(eventPayload: { type: string; payload?: string }) {
    if (
      window &&
      window.convivaAnalytic &&
      window.convivaAnalytic.reportEvent
    ) {
      window.convivaAnalytic.reportEvent('customEvent', eventPayload);
    }
  }

  checkToTriggerSubscriptionNudge() {
    let played_duration = 0;
    if (this.singlePlayDetails && this.singlePlayDetails.watchHistoryDetails) {
      const { watchHistoryDetails = {} } = this.singlePlayDetails || {};
      played_duration = watchHistoryDetails.played_duration
        ? watchHistoryDetails.played_duration
        : 0;
    }
    if (
      this.playerConfig.shouldSubscriptionNudgeDisplayed({
        nudgeType: 'overlay',
        playerRef: this
      }) &&
      !played_duration
    ) {
      return true;
    }
    return false;
  }
  triggerSubscriptionNudge() {
    const { consumption_nudge_duration = 10 } =
      this.playerConfig.getAvodNudgeConfig(this.playerConfig.remoteConfig);
    if (this.videoPlayer.pause) this.videoPlayer.pause();
    subscriptionNudgeOnPlayerPopup(this.videoPlayer, this.playerConfig);
    const continueWatch = document.getElementById('continue-div');
    if (continueWatch) {
      setTimeout(() => {
        toggleSubscribeTeaserBanner(false);
        continueWatch.style = `transition:width ${consumption_nudge_duration}s linear;width:100%;`;
      }, 100);
      setTimeout(() => {
        removeSubscriptionNudgeOnPlayer(this.videoPlayer, this.playerConfig);
      }, consumption_nudge_duration * 1000);
    }
  }
  clearFreeSecondsTimer() {
    clearInterval(this.freeMinutesTimer);
    this.freeMinutesTimer = null;
  }

  clearPlayerReloadTimer() {
    clearTimeout(window.playerReloadTimer);
    window.playerSeconds = 0;
  }

  reloadPlayer() {
    this.clearPlayerReloadTimer();
    this.dispose();
    this.initPlaybackFlow();
  }

  checkFreeMinutesPopup() {
    if (this.freeAvailableSeconds <= 0) {
      this.playerConfig.freeMinutesEndedPopup({
        playerRef: this,
        isFullScreenFlag: false,
        playerConfig: this.playerConfig,
        videoPlayer: this.videoPlayer
      });
      if (this.freeMinutesTimer) {
        [this.clearFreeSecondsTimer()];
      }
    }
  }

  createSpapiRequestTimeStampPayload(singlePlayDetails: any) {
    const spapiTimeStamp = {
      time: singlePlayDetails.spapiRequestimeStamp
    };
    const eventPayload = {
      type: 'SPAPI Request',
      payload: JSON.stringify(spapiTimeStamp)
    };
    return eventPayload;
  }

  createSpapiResponsetTimeStampPayload(singlePlayDetails: any) {
    const spapiTimeStamp = {
      time: singlePlayDetails.spapiResponseTimeStamp
    };
    const eventPayload = {
      type: 'SPAPI Response',
      payload: JSON.stringify(spapiTimeStamp)
    };
    return eventPayload;
  }
  updateUpnextApiState(upNextApiState: boolean, isUpNextPending: boolean) {
    updateUpnextState({ upNextApiState, isUpNextPending });
  }

  // SOS ABR implementation starts here..

  /**
   * ABR (Adaptive Bitrate) Handler function.
   * This function checks if SOS (Server-Over-Server) is enabled for ABR capping
   * using the player configuration retrieved from remote config, and then initiates the SOS interval.
   */

  abrHandler = () => {
    // Determine if SOS is enabled for ABR capping using player configuration
    // If remote config returns falsy value, default to true
    this.isSOSEnabled = this.playerConfig?.isFeatureEnabled(
      FeatureFlagConstant.FEATURE_ABR_CAPPING
    );

    // If SOS is enabled, initiate the SOS interval
    if (this.isSOSEnabled) {
      this.sosInterval();
    }
  };
  /**
   * Function to handle  (SOS) interval.
   * This function fetches system status from a remote URL, extracts the SOS response,
   * applies Adaptive Bitrate (ABR) capping, and schedules the next SOS interval using setTimeout.
   */
  sosInterval = async () => {
    // Default refresh interval in seconds
    let refreshInterval = 60;
    try {
      // Define the URL for fetching system status
      // const url = 'https://stcf-nonprod.zee5.com/dev/frontend/session-control/v1/system-status.json';
      const url = this.playerConfig?.sos_abr_url || '';
      // Fetch system status from the specified URL
      const response: Response = await fetch(url);

      // Parse the JSON response
      const sosResponse = await response.json();

      // Update refresh interval based on the SOS response, default to 60 seconds if not provided
      refreshInterval = sosResponse.refreshInterval || 60;
      // Extract ABR capping information from the SOS response
      const abrCapping = { ...sosResponse.abrCapping };

      // Apply ABR capping using the current instance's method
      this.applySOSCapping(abrCapping);
    } catch (error) {
      // Log any errors during the fetch or processing of the SOS response
      console.error(error || {}, { type: 'GET' });
    } finally {
      // Schedule the next SOS interval using setTimeout
      setTimeout(this.sosInterval, refreshInterval * 1000);
    }
  };

  applySOSCapping(abrCapping: any) {
    if (abrCapping) {
      const { asset_type, asset_subtype, capping } = abrCapping;
      SOShandler(window.videoPlayer, asset_type, asset_subtype, capping);
    }
  }
  // SOS ABR implementation ends here..
}
